
.swapForm input {
    background: #1b1b1b !important;
    border-color: #1b1b1b !important;
    box-shadow: unset !important;
    font-size: 36px;
    font-weight: 485;
}

.swapForm input::placeholder {
    font-size: 36px;
    font-weight: 485;
    color: #5e5e5e !important;
}